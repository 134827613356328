@import "../../../variables";

.menu-burger-button {

  justify-content: space-between;
  align-items: center;

  //position: fixed;
  height: 46px;
  //width: 55px;
  //bottom: 20px;
  //left: 20px;
  z-index: 4;
  //border-radius: 50%;

  display: flex;

  @include phone-l {
    display: flex;
  }

  .menu-button__image {
    display: block;
    margin: 15px;
  }

  .menu-button__image-user {
    margin: 12px;
    height: 26px;
  }

    background-color: $teal;


  &.-hidden {
    display: none;
  }

  &.--test {
    top: 20px;
  }

  &.--results {
    top: 20px;
  }
}