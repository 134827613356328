@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');
@import "../../variables";

.side-menu {
  width: 17%;
  height: 100%;
  //position: fixed;
  z-index: 3;
  //color: white;
  /*min-height: inherit;*/
  background-color: $new_teal;

  &.-hidden {
    display: none;
  }

  //@include pc-m {
    position: fixed;
    width: 30%;
    left: 0;
    transition: left 0.4s;
    min-width: 360px;

    &.-closed {
      left: -100%;
    }
  //}

  @include phone-l {
    width: 100%;
    min-width: 0;
    left: 0;
    transition: left 0.3s;

    &.-closed {
      left: -100%;
    }
  }
}

.side-menu__blackout {

  //@include pc-m {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $slight_transparent_black;
    transition: background-color 0.3s;

    &.-closed,
    &.-hidden {
      width: 0;
      background: transparent;
    }
  //}
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.menu-item {
  position: relative;
  text-align: start;
  padding: 5px 0 5px 28px;
  font-size: 18px;
  font-weight: 400;
}

.menu-item:hover {
  /* background: $orange_transparent; */
  transition: all 0.1s ease-out;
  font-weight: 700;
  cursor: pointer;
}

.menu-item-active {
  background: #2BCFBD2E;
  transition: all 0.2s ease-out;
  font-weight: 700;
  cursor: pointer;
}

.menu-item-active:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4px;
  content: '';
  background: #2BCFBD;
}

.person-label {
  font-family: 'Bebas Neue Regular', cursive;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 32px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 28px;
  margin-bottom: 12px;

  cursor: pointer;
}

.person-img {
  margin-right: 17px;
}

.person-label--name {
  margin: 3px 0 0;
}