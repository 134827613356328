
@font-face {
	font-family: 'Bebas Neue Regular';
	src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
		url('../fonts/BebasNeue/Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Bold';
	src: local('Bebas Neue Bold'), local('BebasNeueBold'),
		url('../fonts/BebasNeue/Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Light';
	src: local('Bebas Neue Light'), local('BebasNeueLight'),
		url('../fonts/BebasNeue/Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Thin';
	src: local('Bebas Neue Thin'), local('BebasNeue-Thin'),
		url('../fonts/BebasNeue/Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue Book';
	src: local('Bebas Neue Book'), local('BebasNeueBook'),
		url('../fonts/BebasNeue/Book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
