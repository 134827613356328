@import "./../../variables";

.lay-div {

    height: 100%;

}

.lay-main {
    height: 100%;
}