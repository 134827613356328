@import "../../variables";
.main-screen {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  font-size: 18px;
  background-color: $almost_white;
  min-height: 100vh;
  height: 100%;

  font-family: 'Mulish', sans-serif;

  //@include pc-m {
    min-height: auto;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
  //}
}

.right-window {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 5%;
  overflow-y: auto;

  //@include pc-m {
    margin: 0;
    height: 100%;
    padding: 0;
  //}

  @include phone-l {
    width: 100%;
    margin: 0;
    height: 100%;
  }
}