.u-unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

// <label class="u-switch">
// <input type="checkbox">
// <span class="u-slider round"></span>
// </label>

/* The switch - the box around the slider */
.u-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.u-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.u-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCC;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.u-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .u-slider {
  background-color: #2196F3;
}

input:focus + .u-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .u-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
